/*!
 * App.scss (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file App.scss
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

$body-bg: #fff;
$body-color: #000000;
$textColor: #303030;
$textSecondaryColor: #fff;

@import 'bootstrap';
@import url('~bootstrap-icons/font/bootstrap-icons.css');
@import url('~primereact/resources/primereact.min.css');
@import 'node_modules/primereact/resources/themes/nova-accent/theme';
@import url('~primeicons/primeicons.css');

@import "_common";
