.email-container {
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.email-label {
  padding-left: 15px;
}

.add-participant {
  padding-left: 20px;
  padding-right: 10px;
}