.selectedIP {
    background-color: #f3f3f3;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: var(--secondary-border-radius);
}

.clickable {
    cursor: pointer;
    align-self: baseline;
}

.selectedIP .pi-times-circle {
    font-size: 0.8rem;
    margin-left: 0.5rem;
    padding-top: 5px;
}

.selectedIP .pi-times-circle:hover {
    background-color: transparent;
}