.custom-datepicker-input {
  font-size: 0.87rem !important;
  height: 40px;
  width: 212px !important;
  border-radius: var(--primary-border-left-radius);
}
.rdrDateRangePickerWrapper {
  margin-top: 35px;
}
@media (max-width: 640px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateRangeWrapper {
    width: 90%;
  }
}

.button-color {
  background: var(--primary-control-color);
}
.p-datepicker-trigger:enabled:hover {
  background: var(--primary-control-hover-color);
  border-color: var(--primary-control-hover-color);
}

#datepickerInput:focus-within  ~ #datepickerButton {
  box-shadow: 0 0 0 0.2rem #8dcdff;
  clip-path: inset(-0.2rem -0.2rem -0.2rem 0px);
}

.license-duration .custom-datepicker-input {
  width: 215px !important;
}