/*
 * sharedCSSVariables.css (AbstractUser)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file sharedCSSVariables.css
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

:root {
  /* Border radius global CSS variables */
  --primary-border-radius: 15px;
  --primary-border-top-radius: 15px 15px 0 0;
  --primary-border-bottom-radius: 0 0 15px 15px;
  --primary-border-right-radius: 0 15px 15px 0;
  --primary-border-left-radius: 15px 0 0 15px;

  --secondary-border-radius: 7px;

  --disabled-color: #6e747e;

  --primary-text-color: #333333;

  --primary-control-color: #007ad9;
  --primary-control-hover-color: #0b5995;
  --primary-control-disabled-color: #337ab7;

  --primary-background-color: #1b1b1b;
}