/*!
 * _prime-components.scss (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *~
 * @file _prime-components.scss
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

$highlightBg: var(--primary-control-color);

.p-datatable .p-datatable-header {
  background-color: var(--secondary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary-control-color);
  border-color: var(--primary-control-color);
  color: $white;
}

.p-dialog {
  border: none;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 75%;
  }

  @include media-breakpoint-up(sm) {
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.p-button {
  cursor: pointer;
  font-size: 16px;

  &.btn-outline-primary {
    font-size: 24px;
  }

  &.p-button-raised {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &:focus {
    box-shadow: none;
  }

  .pi {
    font-size: 1rem;
  }
}

.p-inputtext,
.p-dropdown {
  width: 100%;
}

.p-toggleable-content-enter-active {
  transition: none;
}

.p-toggleable-content-exit-active {
  transition: none;
}

.p-datatable {
  .p-datatable-tbody {
    .p-rowgroup-header {
      color: var(--primary-control-color);
      font-size: 20px;
    }

    &>tr>td {
      text-align: center;
    }

    &>tr.p-highlight {
      background: var(--primary-control-color);
    }
  }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: var(--primary-control-color);
  color: #fff;
}

.p-fieldset {
  .p-toggleable-content {
    height: 100%;

    .p-fieldset-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.p-card {
  display: flex;
  flex-direction: column;

  .p-card-header {
    img {
      height: 300px;
    }
  }

  .p-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-card-footer {
      margin-top: auto;
    }
  }
}

.p-message {
  width: 100%;
}

.p-checkbox {
  .p-checkbox-box.p-highlight {
    border-color: var(--primary-control-color);
    background-color: var(--primary-control-color);
  }
}

.p-col-number {
  width: 110px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-category {
  width: 110px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-price {
  width: 90px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date {
  width: 140px;
  white-space: nowrap;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-updated {
  width: 120px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-date-no-time {
  width: 100px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-bool {
  width: 75px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-image {
  width: 120px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive {
  width: 140px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-descriptive-lg {
  width: 300px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

.p-col-customer {
  width: 200px;
  max-width: 200px;
  min-width: 200px;

  @include media-breakpoint-down(md) {
    width: unset;
    max-width: unset;
    min-width: unset;
  }
}

// SIDE NAV
.p-sidebar {
  background: var(--secondary-color);
  color: white;
  border: 0px;
  z-index: 999;
}

@include media-breakpoint-up(sm) {
  .p-sidebar {
    position: unset;
  }

  .p-sidebar-left {
    width: auto;
  }
}

//SLIDE MENU
.p-slidemenu {
  width: 100%;
}

.p-slidemenu .active {
  background: #eaeaea;
}

.p-tieredmenu .p-menuitem-active>.p-submenu-list {
  z-index: 10;
}

.p-col-descriptive-lg {
  width: 300px;

  @include media-breakpoint-down(md) {
    width: unset;
  }
}

//FIELDSET
.p-fieldset .p-fieldset-legend {
  color: unset;
  background: unset;
  font-weight: normal;
  border: unset;
}

legend {
  width: auto;
}

.status-dropdown-danger {
  background: #ff0f0f;
}

.status-dropdown-success {
  background: #28a745;
}

.status-dropdown-warning {
  background: #ffc107;
}

.status-dropdown-info {
  background: #58BBD7;
}

.col-dropdown .p-inputtext,
.col-dropdown .p-dropdown-trigger {
  color: #fff !important;
}