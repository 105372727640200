.version-dropdown-container {
  width: 125px !important;
}

.version-dropdown-container > .p-dropdown-label.p-inputtext {
  font-size: 12px !important;
}

.download-info .p-card-content {
  padding-top: 0;
}