.datatablecolumn-screen-l i, .datatablecolumn-screen-s i {
    margin-left: 3px;
}

.hide-table-body-while-loading-data .p-datatable-wrapper table tbody, .hide-table-body-while-loading-data .p-paginator {
    display: none;
}
  
.wrapTextOnceBeforeEllipsing {   
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}