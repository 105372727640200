.entitlementModal .p-card-content {
    padding: 0;
}

.entitlement-modal-grid-container {
    display: grid !important;
    grid-template-columns: repeat(4, minmax(300px, 100%));
    row-gap: 15px;
}

.entitlement-modal-grid-container-item {
    min-width: 300px;
    justify-content: center;
    align-items: center;
}

.your-products-description-elision {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 1669px) {
    .entitlement-modal-grid-container {
        grid-template-columns: repeat(3, minmax(300px, 100%));
    }
}

@media (max-width: 1400px) {
    .entitlement-modal-grid-container {
        grid-template-columns: repeat(2, minmax(300px, 100%));
    }
}

@media (max-width: 980px) {
    .entitlement-modal-grid-container {
        display: flex !important;
    }
}

.licenseDataTable.your-license-section .p-datatable .p-datatable-tbody>tr>td:nth-child(3) {
    border-bottom-left-radius: var(--primary-border-radius) !important;
}

@media screen and (max-width: 790px) {
    .custom-entitlement-modal-md-breakpoint {
        .p-datatable table {
            overflow: hidden;
        }

        .p-datatable-tbody>tr {
            position: relative;
        }

        .custom-action-column-action-position {
            position: absolute;
            top: 0;
            right: 0;
        }

        .absolute-position-responsive-screen {
            position: absolute;
        }

        .position-absolute-mr-30 {
            margin-right: 30px;
        }

        .position-absolute-mr-60 {
            margin-right: 60px;
        }

        .position-absolute-mr-90 {
            margin-right: 90px;
        }

        .p-datatable table {
            table-layout: fixed !important;
        }

        /* Remove ring effect on click in chevron icon of data table mobile view */
        body .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0 transparent;
        }

        .responsiveBaseDataTable .p-datatable-tbody>tr>td>button>i,
        .responsiveBaseDataTable .p-datatable-tbody>tr>td>button>span {
            margin-left: -10px;
        }

        .custom-action-column-margin-mobile-view {
            margin-top: -3px !important;
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-thead>tr>th,
        .p-datatable .p-datatable-tfoot>tr>td {
            display: none !important;
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-tbody>tr>td {
            text-align: left;
            display: block;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;
            padding: 2px 5px;
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-tbody>tr>td .titleCol {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4em 1em -0.4em -0.4rem;
            font-weight: bold;
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-tbody>tr {
            border-bottom: 1px solid var(--surface-d);
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-tbody>tr>td .datatablecolumn-screen-l {
            display: none;
        }

        .responsiveBaseDataTable .p-datatable .p-datatable-tbody>tr>td .datatablecolumn-screen-s {
            display: table;
            width: 100%;
        }

        .nestedDatatable-withoutPagination .p-datatable table,
        .datatable-withoutPagination .p-datatable table {
            border-collapse: collapse;
        }

        .table-col-xs-10 {
            width: 100px;
        }
    }

}