.sidebarCol {
  display: block;
  padding: 0;
}

.sidebarCol .sidebarContainer {
  height: 100%;
  min-height: 100vh;
  background: var(--primary-background-color);
  font-size: 14px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
  transition: margin 0.25s ease-out;
}

.sidebarCol .brand-image-container {
  height: 62px;
}

.sidebarCol .sidebarContainer .menuHeader {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.sidebarCol .sidebarContainer .menuFooter {
  padding: 0.3rem 1.2rem;
}

.sidebarCol .sidebarContainer .menuFooterIcons {
  width: 15px;
  height: 15px;
}

.sidebarCol .sidebarContainer .userDetailBox {
  margin: 0;
}

.sidebarCol .sidebarContainer .nav-link {
  cursor: pointer;
}

.sidebarNav {
  display: none;
  background: var(--primary-background-color);
  font-size: 14px;
}

.sidebarNav .userNavBox {
  padding: 12px 8px 0 8px;
}

.custom-link-btn {
  font-weight: bold;
  color: #cbd3da;
  font-size: 14px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
}

.custom-link-btn:focus,
.custom-link-btn:hover {
  text-decoration: none;
  color: #fff;
  box-shadow: none;
}

.p-submenu-header {
  position: relative;
}

.p-submenu-header:after {
  content: '';
  position: relative;
  overflow: hidden;
  padding: 0.857rem;
}

.p-submenu-header.activeMenuItemLink::before,
.p-menuitem.activeMenuItemLink::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 6px;
  left: -10px;
  background-color: white;
}

.p-menu .p-menuitem {
  position: relative;
}

.p-menuitem.activeMenuItemLink .p-menuitem-link {
  font-weight: bold;
}

.p-tieredmenu,
.p-menu,
.p-tieredmenu .p-submenu-list {
  width: 100%;
  color: #cbd3da;
  background: var(--primary-background-color);
  border: 0px;
  padding: 5px;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text,
.p-tieredmenu .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem-link .p-menuitem-text,
.p-menu .p-menuitem-link .p-menuitem-icon,
.p-submenu-list .p-menuitem-link {
  font-size: 14px !important;
}

.p-menu .p-menuitem-link:focus,
.p-tieredmenu .p-menuitem-link:focus,
.p-submenu-list .p-menuitem-link:focus {
  box-shadow: none !important;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover,
.p-submenu-list .p-menuitem-link:not(.p-disabled):hover {
  background: transparent !important;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #fff !important;
}

.p-menu .p-menuitem-link .p-menuitem-text,
.p-menu .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem-link .p-menuitem-text,
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #cbd3da !important;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #cbd3da !important;
  margin-top: 5px;
}

.p-menu .activeMenuItemLink .p-menuitem-text,
.p-tieredmenu .activeMenuItemLink .p-menuitem-text,
.p-tieredmenu
  .activeMenuItemLink
  .p-menuitem-icon
  .p-tieredmenu
  .activeMenuItemLink
  .p-submenu-icon {
  color: #fff !important;
}

.p-menu .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-tieredmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text
  .p-tieredmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon {
  background: transparent !important;
}

.p-menu .p-submenu-header {
  color: #cbd3da !important;
  background: transparent;
}

@media (min-width: 1000px) {
  .accountCol {
    width: 255px;
  }
  .row {
    display: flex;
  }
  .sidebarCol{
    min-width: 255px;
    max-width: 255px;
}
  .main-body {
    max-width: calc(100% - 255px);
  }
  .navbar-brand img {
    max-width: '100%';
  }
}

/* start of sidebar panelmenu styles */
.p-panelmenu .p-panelmenu-content {
  border: none;
  background: transparent;
  margin: 0;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none;
  border: none !important;
}

.p-panelmenu .p-panelmenu-header > a {
  border: none !important;
  background: transparent;
  font-weight: 400;
  color: #cbd3da;
  padding: 0;
}

.p-menuitem-text {
  font-size: 14px;
}

.p-panelmenu-header-link, .p-menuitem-link {
  height: 40px;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: none !important; 
  color: #fff;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: transparent !important;
} 

.count-items-container {
  display: flex;
  align-items: center;
}

.count-items-container .count-item-style {
  margin-left: 10px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #898484 !important;
  color: #898484 !important;
  width: auto;
  font-size: 12px;
}

.panel-menu-activated-item {
  background: transparent !important;
  border-left: solid 4px var(--accent-color) !important;
  border-radius: 0 !important;
}

.panel-menu-activated-item .p-panelmenu-header > a {
  color: #ffffff;
}

.p-panelmenu-header-link[aria-expanded="true"] .p-panelmenu-icon {
  transform: rotate(180deg);
  font-size: 12px;
}

.p-panelmenu-header-link .p-panelmenu-icon {
  transform: rotate(90deg);
  font-size: 12px;
}

.panel-menu-inactived-item {
  border-left: none !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link, .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link > span {
  color: #cbd3da !important;

  display: flex;
  align-items: center;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 13px 31px;
}

.p-tieredmenu .p-menuitem-link {
  padding: 9px 7px !important;
}

.separator-menu-item {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #d8dae2;
  max-height: 10px !important;
}

.p-panelmenu-icon {
  order: 1;
}

.p-menuitem-text {
  flex-grow: 1;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link, .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link > span:hover {
  color: #fff !important;
}
/* end of sidebar panelmenu styles */

@media (max-width: 991.98px) {
  .sidebarCol {
    display: none;
  }

  button .navbar-toggler {
    margin-left: 8px !important;
  }

  .sidebarNav {
    position: relative;
    display: block;
    color: #fff;
    border-top: solid 4px var(--accent-color);
    padding-left: 0 !important;
  }

  .sidebarNav .navbar-toggler {
    float: right;
    margin-right: 20px;
  }

  .navbar-collapse {
    background: var(--primary-background-color) !important;
    position: absolute;
    width: 255px;
    border-bottom-right-radius: 10px;
    z-index: 1;
  }
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

/* .p-panelmenu .p-panelmenu-header.p-highlight,  */
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-left: 10px;
}

/* Styles for Font Awesome icons. */
.sidebar_icon {
  font-size: 1rem;
  text-align: center;
  width: 1.3em;
}

.theme-mode-button button {
  border: 1px solid #666 !important;
  border-radius: var(--primary-border-radius) !important;
}

.theme-mode-button-from-login-page button {
  background-color: #ffffff !important;
  border: 1px solid #a6a6a6 !important;
}

.theme-mode-button-from-sidebar,
.theme-mode-button-from-sidebar > button {
  background: var(--primary-background-color) !important;
}

.theme-mode-button .p-button-label {
  font-size: 14px;
}

.theme-mode-button .p-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 36.72px;
  border-radius: var(--secondary-border-radius) !important;
}

.theme-mode-button .p-button-icon-left {
  margin-right: 0.5rem;
  margin-left: 5px;
}

.logout_button {
  margin-left: 0.7rem;
}

.theme-mode-button:hover button {
  border: 1px solid var(--text-color) !important;
}

.theme-mode-button:hover button span {
  color: #ffffff;
}

.theme-mode-button-from-sidebar span {
  flex: none;
  color: #cbd3da;
}

.theme-mode-button-from-login-page span {
  flex: none;
  color: #0000006e !important;
}

.menuHeader.row .brand-image-container img {
  max-width: 100%;
}

.language-menu {
  width: max-content;
  height: 36.72px;
}