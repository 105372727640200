.newsletter-preview {
  height: 100%;
  pointer-events: none;
  min-width: 100px;
}

.newsletter-dropdown, .newsletter-inputText {
  width: 300px !important;
}

.horizontal-splitter-panel .newsletter-preview {
  height: 100vh;
}

.preview-button-container {
  min-width: 81px;
}

.newsletter-button-head-container {
  width: 2%;
}

.send-button-container {
  min-width: 84px;
}

@media (min-width: 1675px) {
  .newsletter-created-date-column {
    display: table-cell !important;
  }
}

#popup-root {
  z-index: 10000;
}