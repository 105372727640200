.main-profile-card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative !important;

  max-width: 100%;
  width: 226px;
  outline-color: #3f3f46;
  outline-width: 1px;
  outline-style: solid;
  outline-offset: 2px;
  min-height: 100px;
  border-radius: var(--secondary-border-radius);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* Style for the responsiveness sidebar */
@media (max-width: 991.98px) {
  .main-profile-card-container {
    margin-top: 10px;
  }
}

.main-profile-card-container.from-sidebar-component:hover {
  box-shadow: 0 0 var(--secondary-border-radius) var(--primary-control-color);
  cursor: pointer;
}

.main-profile-card-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('./assets/profile-card-background.png');
  background-size: cover;
  filter: grayscale(1) invert(1);
  pointer-events: none;
  opacity: .3;
  background-repeat: repeat;
}

.notification-and-abstract-id-image-container {
  height: 12px;
}

.abstract-id-img-container {
  height: auto;
  max-width: 100%;
  max-height: 12px;
  opacity: .9;
}

.abstract-id-img-container-margin-left {
  margin-left: 26px; 
}

.token-container {
  display: flex;
  align-items: center;
  justify-content: center;

  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
  outline-color: #10b981;
  outline-width: 1px;
  outline-style: solid;
  border-radius: 4px;
  
  width: 100%;
  height: 24px;

  backdrop-filter: blur(12px);
}

.token-container-image-section {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  opacity: .8;
}

.token-container-tokens-value {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
  font-size: 16px;
}

.user-information-container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 16px;
  font-weight: 500;
}

.user-information-container .user-email{
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  font-size: 12px;
  line-height: 1rem;
  word-break: break-all;
}

.user-image-container {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 4px;
  object-fit: contain;
}

.no-user-image-container {
  background: #4B5563;
  backdrop-filter: blur(12px);
}

.bottom-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-buttons-container button {
  color: #9CA3AF;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.bottom-buttons-container button svg {
  width: 14px;
  height: 14px;
}

.bottom-buttons-container button:hover {
  text-decoration: underline;
  color: #FFFFFF;
}

.notification-container {
  width: 40px;
  max-height: 12px;
  background-color: #374151;
  border-radius: 4px;
  color: #D1D5DB;
  backdrop-filter: blur(12px);
  font-weight: 500;
  font-size: 11px;
  line-height: 8px;
  padding: 2px 4px;
}

.notification-container svg {
  width: 8px;
  height: 8px;
}

.account-icon-container {
  margin-right: 12px;
}

.account-icon-margin {
  margin: 0 6px 4px 0;
}

.signout-icon-margin {
  margin: 0 4px 2px 2px;
}

.custom-tokens-label-container {
  margin-top: 0.5px;
  font-size: 14px;
}

[data-initials]:before {
  background: var(--primary-control-color);
  color: white;
  opacity: 1; 
  content: attr(data-initials); 
  display: inline-block; 
  font-weight: bold; 
  border-radius: 50%; 
  vertical-align: middle; 
  margin-right: 0.5em; 
  width: 50px !important; 
  height: 50px !important; 
  line-height: 50px !important; 
  text-align: center; 
}