.settingsForm {
  width: 300px;
}

@media (max-width: 677px) {
  .settingsForm {
    width: 100%;
    margin: 0 !important;
  }

  .settingsForm:not(:last-child) {
    margin-bottom: 5px !important;
  }
}
