.alert-message {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(155 28 28 / 1);
  background-color: rgb(253 242 242 / 1);
  border-color: #f8b4b4 !important;
  border: 1px solid;
  border-radius: 0.5rem;
  gap: 0.75rem;
}

.confirmation-modal-warning-message {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #595959;
  background-color: #ffeaa8;
  border-color: #ffeaa8 !important;
  border: 1px solid;
  border-radius: 0.5rem;
  gap: 0.75rem;
}

.confirmation-dialog-icon-message {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity)); 
}