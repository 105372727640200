/* Move this to common css by creating a new file under AbstractWebCommon/lib/common.css */
.bg-checkerboard {
  background-image: /* tint image */ linear-gradient(
      to right,
      rgba(225, 225, 225, 0.75),
      rgba(225, 225, 225, 0.75)
    ),
    /* checkered effect */ linear-gradient(to right, black 50%, white 50%),
    linear-gradient(to bottom, black 50%, white 50%);
  background-blend-mode: normal, difference, normal;
  background-size: 2em 2em;
}

.removeImageBtn {
  display: inline;
  position: absolute;
  border-radius: 10em;
  padding: 3px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(53, 49, 49, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.removeImageBtn:hover {
  background: #e54e4e;
  color: white;
  border: 3px solid #fff;
  text-decoration: none;
}

.removeImageBtn:active {
  background: #e54e4e;
  color: white;
  border: 3px solid #fff;
  text-decoration: none;
}

.rootImageContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logoAndFavouriteIconFieldset .p-fieldset-legend-text {
  font-size: 16px;
  font-style: normal;
  height: 2px;
}

.bordered-file-input {
  border: 1px solid #dcdee0;
  border-radius: 0.3rem;
  padding: 0.3rem;
  overflow: visible;
}

.custom-file-label::after {
  display: none !important;
}

.custom-upload-and-remove-file-width {
  max-width: 342px;
  width: 100%
}

.custom-file-label {
  border-radius: 25px;
}