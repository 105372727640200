.template-preview {
  height: 100%;
  pointer-events: none;
  min-width: 100px;
}

.dropdown-input-container {
  max-width: 500px !important;
  height: 39.72px;
}

.custom-html-invalid-field .CodeMirror {
  border: 2px solid #a80000;
  border-radius: var(--primary-border-radius);
}

.custom-button-height {
  height: 44px;
  padding: 0.375rem 0.75rem;
}

.custom-skeleton-container {
  border-radius: var(--primary-border-radius) !important;
}

.templates .p-splitter {
  border: none;
}
.templates .p-splitter-horizontal .p-splitter-gutter {
  width: 6px !important;
  margin-top: 42px;
  margin-bottom: 15px;
}
.templates .p-splitter-vertical .p-splitter-gutter {
  height: 6px !important;
  margin-right: 10px;
  margin-left: 10px;
}
.vertical-splitter {
  height: 1000px;
 }
.horizontal-splitter-panel .template-preview {
  height: 100vh;
  margin-top: -7px;
}
.CodeMirror {
  height: 100vh !important;
  min-width: 100px;
  border-radius: var(--primary-border-radius);
  background-color: #282a36;
}
.codemirror-splitterPanel {
  overflow: hidden;
}
.vertical-splitter-panel .react-codemirror2, .vertical-splitter-panel .CodeMirror {
  height: 100% !important;
}