.deactivation-button-container {
  min-width: 151px;
}
.auto-complete > .p-autocomplete-input {
  border-right: transparent;
}

.auto-complete > .p-autocomplete-input:hover {
  border: 1px solid #a6a6a6;
  border-right: transparent;
}

.auto-complete > .p-autocomplete-dropdown {
  border: 1px solid #a6a6a6;
  background: transparent;
  border-left: transparent;
  color: grey;
  margin-left: -35px;
}
.auto-complete > .p-autocomplete-dropdown:hover {
  border: 1px solid #a6a6a6;
  background: transparent;
  border-left: transparent;
  color: grey;
}

.custom-auto-complete-deactivation-page {
  width: 100%;
  max-width: 500px !important;
}

/* Removing the arrow icon of the auto complete component to prevent users checking the entire user list */
button[aria-label="Choose"] {
  display: none;
}