.colWidth__120_const {
  width: 120px;
}

.logDetailsContainer {
  text-align: left;
}
.logDetailsKey {
  color: #fff !important;
  white-space: nowrap;
  display: inline-block;
}

.logDetailsValue {
  width: 350px;
  height: 25px;
}

.logEmptyTd {
  width: 10px;
  display: inline-block;
  text-align: center;
}

.logArrowTd {
  width: 10px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.error-category {
  white-space: pre-line;
}

.logs-custom-p-tag {
  background-color: #FFFFFF;
  color: #6c757d !important;
}

/* Media query for details field of expansion row */
@media only screen and (max-width: 650px) {
  .logDetailsContainer {
    text-align: left;
    margin: 0px;
  }

  .logDetailsKey {
    width: 100%;
    white-space: nowrap;
    display: inline-block;
  }
  
  .logDetailsValue {
    width: 100%;
    height: fit-content;
    margin: 5px 0;
    display: inline-block;
  }
}