:root {
    --primary-dark-color: #383838;
    --secondary-dark-color: #303030;
    --primary-dark-border-color: #333;
    --primary-hover-dark-color: #464a4c;

    --text-color: #CCC;
    --skeleton-dark-color: #515050;
    --tooltip-background-dark-color: #858080;
    --tableheader-and-footer-dark-color: #212121;
    --white-color: #FFF;
}

.dark-mode {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .p-card-title,
    .p-card-content,
    .p-tabview-title,
    .pl-0,
    .btn,
    .description,
    .braintree-sheet__text,
    .braintree-form__label,
    .reportsDataTable .link,
    .rdrDayNumber span,
    .rdrMonthAndYearPickers select,
    .email-link-custom-container i,
    .braintree-dropin .braintree-heading,
    .form-label,
    .p-autocomplete-item,
    .logs-custom-p-tag,
    .error-category,
    .error-page,
    .cropper-text,
    .p-fieldset,
    .p-dialog .p-dialog-header,
    .p-dialog .p-dialog-content,
    .p-dropdown-empty-message,
    .braintree-large-button,
    .p-steps-title,
    .p-panel .p-panel-header,
    .p-panel .p-panel-header .p-panel-title,
    .p-chip,
    .additional-options-panel .p-panel-header,
    .additional-options-panel .p-panel-content,
    .braintree-form__descriptor {
        color: var(--text-color) !important;
    }  

    .comment-markdown code:not(pre code) {
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(110, 118, 129, 0.4);
        border-radius: 6px;
    }
      
    .comment-markdown pre {
        line-height: 1.45;
        background-color: #161b22;
        border-radius: 6px;
        margin-bottom: 16px !important;
    }
      
    .comment-markdown pre code {
        padding: 16px;
        overflow: auto;
        display: block;
        color: var(--text-color);
    }

    // We should apply specific CSS properties to the profile card component while on the dark mode.
    .main-profile-card-container > .bottom-buttons-container > .custom-link-btn {
        color: #9CA3AF !important;
    }

    .main-profile-card-container > .bottom-buttons-container > .custom-link-btn:hover {
        color: var(--white-color) !important;
    }
    // Finishing to apply specific CSS properties to the profile card component while on the dark mode.

    .selectedProduct {
        outline: 2px solid var(--primary-control-color) !important;
    }

    //NOTE: Apply specific CSS for the images in the dashboard of License project, for the client view.
    .convert-image-filter {
        filter: grayscale(1) invert(1);
    }

    .p-tabview-nav-link:hover,
    .p-datatable .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-row-expansion):hover,
    .p-datatable-tbody > tr:not(.p-highlight):hover a {
        background: var(--primary-hover-dark-color) !important;
    }

    .p-datatable .p-datatable-tbody > tr .p-datatable-row-expansion:hover {
        background: transparent !important;
    }

    .report-section {

        p,
        label,
        .comment-markdown,
        span {
            color: var(--text-color) !important;
        }

        .comment-internal {
            background: rgb(91 91 91);
        }
    }

    .alert-warning {
        color: #ffd251;
        background-color: #594200 !important;
    }

    .profile-card i:hover {
        color: var(--primary-control-color) !important;
    }

    .main-body,
    .light-section,
    .braintree-sheet,
    #dashboardProduct .card,
    .profile-card-dark-mode,
    .p-autocomplete .p-autocomplete-panel,
    #root {
        background: #383838 !important;
    }

    .permissions-container .alert-secondary {
        color: #FFFFFF;
        background-color: #383d41;
    }

    .login-pages-global-container .p-card .p-card-body,
    .verificationForm .p-card .p-card-body {
        background: var(--primary-dark-color) !important;
        border-radius: var(--primary-border-radius);
        border: 4px solid;
    }

    footer {
        background: var(--primary-dark-color) !important;
    }

    .alert-warning,
    .p-tabview .p-tabview-panels,
    .p-panel .p-panel-content {
        background: transparent;
        border: none;
        box-shadow: none;
    }

    .date-picker-container,
    .rdrDefinedRangesWrapper,
    .rdrCalendarWrapper,
    .rdrStaticRange,
    .rdrDateDisplayWrapper,
    .popover-body {
        background: var(--secondary-dark-color);
        color: var(--text-color);
    }

    .p-fieldset,
    .p-dialog .p-dialog-content,
    .p-dialog-footer {
        background: var(--secondary-dark-color);
    }

    .p-datatable .p-datatable-tbody > tr.p-highlight > td > span > i, 
    .p-datatable .p-datatable-tbody>tr.p-highlight a,
    .p-datatable .p-datatable-tbody>tr.p-highlight,
    .p-datatable .p-datatable-tbody>tr.p-highlight>td .editIcon {
        color: var(--white-color) !important;
    }

    .p-datatable {
        .p-datatable-thead>tr>th {
            text-align: center !important;
            background: var(--tableheader-and-footer-dark-color) !important;
            color: var(--text-color) !important;
            text-transform: capitalize !important;
            border: 1px solid var(--primary-dark-border-color);
        }

        .p-datatable-tbody>tr:not(.p-highlight) {
            background: var(--secondary-dark-color) !important;
            color: var(--text-color) !important;
        }

        .p-sortable-column:hover:hover {
            background: #313030 !important;
        }

        .p-datatable .p-datatable-tbody>tr.p-highlight,
        .p-datatable .p-sortable-column.p-highlight,
        .p-datatable .p-sortable-column.p-highlight:hover,
        .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
        .p-multiselect-panel .p-dropdown-items .p-dropdown-item.p-highlight,
        .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
            background: var(--primary-control-color);
        }

       

        @media (min-width: 576px) {
            .p-datatable-tbody>tr>td {
                border: 1px solid var(--primary-dark-border-color);
            }

            .p-datatable-header {
                border-color: var(--primary-dark-border-color);
                border-style: solid;
                border-width: 1px 1px 0 1px;
            }

            .p-paginator {
                border-color: var(--primary-dark-border-color);
                border-style: solid;
                border-width: 0 1px 1px 1px;
                background-color: var(--tableheader-and-footer-dark-color);
            }
        }

        .logs-custom-p-tag,
        .p-datatable-header {
            background-color: var(--tableheader-and-footer-dark-color) !important;
        }
    }

    @media (max-width: 576px) {
        .p-datatable .p-paginator {
            border-color: var(--primary-dark-border-color);
            border: 0;
            background-color: var(--tableheader-and-footer-dark-color);
        }
        .nestedDatatable .p-datatable table:not(.datatablecolumn-screen-s) {
            border: 1px solid var(--tableheader-and-footer-dark-color);
        }
    }

    .list-group-item,
    .p-dialog-header,
    .p-fieldset .p-fieldset-legend,
    .additional-options-panel .p-panel-header {
        background: var(--tableheader-and-footer-dark-color) !important;
        color: var(--text-color) !important;
    }

    hr {
        border-top-color: #6e6c6c !important;
    }

    .tooltip-inner {
        background-color: var(--tooltip-background-dark-color);
        opacity: 1;
    }

    .p-dropdown-label .p-inputtext,
    .p-dropdown .p-dropdown-label {
        border: none;
    }

    .bs-tooltip-bottom .arrow::before,
    .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
        border-bottom-color: var(--tooltip-background-dark-color);
    }

    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^=top] .arrow::before {
        border-top-color: var(--tooltip-background-dark-color);
    }

    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^=right] .arrow::before {
        border-right-color: var(--tooltip-background-dark-color);
    }

    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^=left] .arrow::before {
        border-left-color: var(--tooltip-background-dark-color);
    }

    .p-dropdown-label .p-inputtext,
    .p-dropdown .p-dropdown-label {
        background: none;
    }

    .theme-mode-button-from-login-page button {
        background: var(--primary-dark-color) !important;
        border: 1px solid #666;
    }

    .theme-mode-button-from-login-page button span {
        color: #cbd3da !important;
    }

    .theme-mode-button-from-login-page .p-button.p-button-text:enabled:hover  {
        background: var(--secondary-dark-color) !important;
        border: 1px solid var(--text-color) !important;
    }

    .theme-mode-button.custom-theme-mode-className:hover button span  {
        color: #ffffff !important;
    }

    input:not([type=file]),
    .p-inputtext,
    .p-multiselect,
    .p-multiselect-panel,
    .p-multiselect-panel .p-multiselect-header,
    .p-dropdown-panel,
    .p-autocomplete-panel,
    .p-dropdown,
    .p-dropdown-header,
    .form-control:disabled,
    .form-control[readonly],
    .react-multi-email,
    .react-multi-email.focused,
    .dropzone,
    textarea,
    textarea:focus,
    .rdrDateDisplayItem input,
    .form-control:focus {
        background: var(--secondary-dark-color);
        border: 1px solid #666;
        color: var(--text-color) !important;
        color-scheme: dark;
    }

    .p-multiselect-panel .p-multiselect-header,
    .p-dropdown .p-dropdown-panel .p-dropdown-header {
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
    }

    .p-inputtext:enabled:hover,
    .p-multiselect:not(.p-disabled):hover,
    .p-dropdown:not(.p-disabled):hover,
    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
        border: 1px solid var(--text-color);
    }

    .p-inputtext:enabled:focus,
    .p-multiselect:not(.p-disabled).p-focus,
    .p-dropdown:not(.p-disabled).p-focus,
    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
        border-color: #007ad9;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover,
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover,
    .rdrStaticRange:hover .rdrStaticRangeLabel,
    .rdrStaticRange:focus .rdrStaticRangeLabel,
    .forgotPasswordPage-container,
    .activationContainer,
    .grey-background-to-activation-page,
    .p-autocomplete-item:hover {
        background: var(--primary-dark-color) !important;
    }

    input::placeholder,
    .p-multiselect-label,
    .p-dropdown-label,
    .p-dropdown-item,
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item,
    .profile-card .card-text div,
    .profile-card .card-text i {
        color: var(--text-color) !important;
    }

    .custom-fieldset .p-fieldset-legend {
        background: var(--secondary-dark-color) !important;
    }

    .card,
    .p-card,
    .custom-fieldset .p-fieldset-legend {
        border: none;
        background: var(--secondary-dark-color);
        color: var(--text-color);
    }

    .login-container,
    .registerForm-container,
    .login-container 
    input:not([type=file]),
    .p-dropdown-panel,
    .p-autocomplete-panel,
    .p-dropdown,
    .p-dropdown-header,
    .forgotPasswordPage-container input:not([type=file]),
    .registerForm-container input:not([type=file]) {
        background: var(--primary-dark-color);
    }

    .react-loading-skeleton {
        background-color: var(--skeleton-dark-color);
        background-image: linear-gradient(90deg, var(--skeleton-dark-color), #5f5e5e, var(--skeleton-dark-color));
    }

    .border {
        border-color: var(--skeleton-dark-color) !important;
    }

    .p-fieldset .p-fieldset-content,
    .p-fieldset {
        border: none;
    }

    .p-tabview .p-tabview-panels {
        border-top: 2px solid var(--skeleton-dark-color);
    }

    .custom-link-btn {
        color: #cbd3da !important;
    }

    .custom-link-btn:focus,
    .custom-link-btn:hover {
        color: #fff !important;
    }

    .loginForm input:-webkit-autofill,
    .registerForm-container input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--primary-dark-color) inset !important;
        background-color: var(--primary-dark-color);
        background-clip: content-box;
    }

    .cropper-height>div {
        background-color: #343a40;
    }

    .profile-card i:hover,
    .profile-card i:hover div {
        color: var(--primary-control-color) !important;
    }

    .custom-border-bottom-style,
    .table thead th {
        border-bottom: 1px solid var(--primary-dark-border-color);
    }

    .custom-border-top-style,
    .table thead th,
    .table tbody td {
        border-top: 1px solid var(--primary-dark-border-color);
    }

    .report-title, .report-description {
        border-color: transparent;
        background: none;
    }
    .report-textarea:enabled:hover {
        border-color: transparent;
        background: var(--secondary-dark-color);
    }
    .email-autocomplete .p-autocomplete-token {
        background: var(--tableheader-and-footer-dark-color) !important;
        color: var(--text-color) !important;
    }

    .nestedDatatable-withoutPagination {
        border-radius: var(--primary-border-radius);
    }
    .nestedDatatable .p-datatable .p-datatable-thead > tr > th,
    .nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child td,
    .nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child {
        border-bottom-color: var(--tableheader-and-footer-dark-color);
    }
    .customerDatatable .p-datatable .p-datatable-thead > tr > th {
        border-top-color: var(--tableheader-and-footer-dark-color);
    }
    .nestedDatatable .p-datatable .p-datatable-thead > tr:last-child th:first-child,
    .nestedDatatable .p-datatable .p-datatable-tbody > tr > td:first-child,
    .nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td:first-child,
    .nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td:nth-child(2),
    .customerDatatable .p-datatable .p-datatable-thead > tr:first-child th:nth-child(2)
    { 
        border-left-color: var(--tableheader-and-footer-dark-color);
    }
    .nestedDatatable .p-datatable .p-datatable-thead > tr:last-child th.last-visible-child,
    .nestedDatatable .p-datatable .p-datatable-tbody > tr > td.last-visible-child { 
        border-right-color: var(--tableheader-and-footer-dark-color);
    }
    .nestedDatatable .p-datatable .p-datatable-header {
        border-left-color: var(--tableheader-and-footer-dark-color);
        border-right-color: var(--tableheader-and-footer-dark-color);
        border-top-color: var(--tableheader-and-footer-dark-color);
    }
    .nestedDatatable .p-datatable .p-paginator {
        border-color: var(--tableheader-and-footer-dark-color);
    }

    .react-json-view {
        width: 100%;
    }

    .checkout .p-fieldset-content, .billing-form .profile-card, .transactions-table-expansion .p-fieldset-content {
        border: 1px solid var(--tableheader-and-footer-dark-color);
    }
    .checkout .p-fieldset-legend, .transactions-table-expansion .p-fieldset-legend {
        background-color: var(--tableheader-and-footer-dark-color) !important;
        border-color: var(--primary-dark-border-color);
        border-style: solid;
        border-width: 1px 1px 0 1px;
    }
    .checkout .table thead th, .transactions-table-expansion .table thead th {
        border-bottom-color: #6e6c6c;
    }
    .checkout .table tbody td, .transactions-table-expansion .table tbody td {
        border-top-color: #6e6c6c;
    }
    .p-datepicker-trigger {
        color: var(--text-color) !important;
    }
    .bs-popover-bottom > .arrow::after, .bs-popover-bottom .popover-header::before {
        border-bottom-color: var(--tooltip-background-dark-color);
    }
    .bs-popover-top > .arrow::after, .bs-popover-top .popover-header::before {
        border-top-color: var(--tooltip-background-dark-color);
    }
    .bs-popover-right > .arrow::after, .bs-popover-right .popover-header::before {
        border-right-color: var(--tooltip-background-dark-color);
    }
    .bs-popover-left > .arrow::after, .bs-popover-left .popover-header::before {
        border-left-color: var(--tooltip-background-dark-color);
    }

    .file-attachment {
        border-color: #666;
    }
    
    .p-splitter{
        background: transparent !important;
    }
    .p-splitter-panel,
    .p-splitter-gutter {
        background: #383838 !important;
        color: var(--text-color) !important;
    }
    .p-splitter-gutter {
        background: var(--secondary-dark-color) !important;
    }
    .p-splitter-gutter-resizing {
        background: transparent !important;
    }
    .p-splitter-gutter-handle {
        background: #666 !important;
    }
    .p-editor-container,
    .ql-container .ql-editor,
    .ql-tooltip,
    .ql-snow .ql-tooltip input[type=text] {
        background: var(--secondary-dark-color) !important;
        color: var(--text-color) !important;
        color-scheme: dark !important;
    }
    .ql-editor a{
        background-color: transparent !important;
    }
    .p-editor-toolbar,
    .p-editor-content,
    .ql-tooltip,
    .ql-snow .ql-tooltip input[type=text] {
        border: 1px solid #666 !important;
    }
    .p-editor-toolbar {
        background: var(--tableheader-and-footer-dark-color) !important;
    }
    .ql-snow .ql-tooltip input[type=text]:enabled:hover {
        border: 1px solid var(--text-color) !important;
    }
    .p-inputtext::placeholder {
        color: var(--disabled-color) !important;
    }
    .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):active {
        background: var(--primary-control-color) !important;
    }
    .active-participants .p-card {
        background: var(--tableheader-and-footer-dark-color) !important;
    }
    .p-chip {
        background: var(--secondary-dark-color) !important;
    }
    .additional-options-panel .p-panel-content {
       background: var(--primary-dark-color);
    }
    .braintree-upper-container:before, .braintree-paypal {
        border: 1px solid;
        border-radius: var(--primary-border-radius);
    }
    .braintree-card, 
    .braintree-sheet__header, 
    .braintree-paypal, 
    .braintree-upper-container:before, .braintree-paypal {
        border-color: var(--tableheader-and-footer-dark-color);
    }
    .w-md-editor-text-input {
        -webkit-text-fill-color: var(--text-color);
    }
}