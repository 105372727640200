.custom-start-and-end-date-width-column {
  width: 120px
}

@media (max-width: 575px) {
  .wrap-header {
    display: flex;
    flex-wrap: wrap;
  }

  .margin-bottom-on-wrap {
    margin-bottom: 6px;
  }
}

.viewAsClient-button-container {
  min-width: 122px;
}

.viewAsClient-button-head-container {
  width: 2%;
}

@media (min-width: 1675px) {
  .license-created-date-column {
    display: table-cell !important;
  }
}

/* Removing the arrow icon of the auto complete component to prevent users checking the entire user list */
button[aria-label="Search by name or email"], button[aria-label="Search User"] {
  display: none;
}