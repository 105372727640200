/* Copied from license common, can be moved to a new awc css common */
@keyframes show-pink {
  0% {
    color: black;
  }

  100% {
    color: var(--primary-control-color);
    text-decoration: underline;
    font-weight: bold;
  }
}

.feedLabel:hover {
  cursor: pointer;
  animation-name: show-pink;
  /* animation-duration: 300ms; //NOTE: property disabled due AW-2349 */
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  cursor: pointer;
}

.showMoreFeeds {
  text-align: center;
  margin-top: 20px;
}

.justifyCenter {
  display: grid;
  place-items: center;
}

.custom-feed-container .p-card-content {
  padding-top: 0;
}

.custom-margin-between-news {
  margin-top: 20px ;
}