.p-editor-content, .ql-snow .ql-tooltip input[type=text] {
  font-family: var(--font-family);
  font-size: 16px;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke:#848484;
}
.p-editor-container .p-editor-toolbar {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.p-editor-container .p-editor-content, .p-editor-container .p-editor-content .ql-editor {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.ql-snow .ql-tooltip input[type=text] {
  width: calc(100% - 150px);
  height: 39.72px;
  border-radius: 15px;
 }
.ql-snow .ql-tooltip input[type=text]:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #8dcdff;
  border-color: #007ad9;
}
.ql-snow .ql-tooltip input[type=text]:enabled:hover {
  border-color: #212121;
}
.ql-tooltip {
  border-radius: 15px;
  box-shadow: none !important;
  left: 10px !important;
  width: calc(100% - 20px);
}
.ql-preview {
  width: calc(100% - 200px);
  max-width: none !important;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #333333;
}
.p-editor-container .p-editor-toolbar {
  background: #333333;
}
.p-editor-content {
  min-height: 320px;
}