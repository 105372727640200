.customLanguageSelectorDropdown {
  height: 36.72px;
  max-width: 71.41px;
}

.isFromSidebar {
  background-color: var(--primary-background-color) !important;
}

.customZIndex {
  z-index: 1030 !important;
}

.customOptionsOverlayContainer li {
  height: 35px;
  display: flex;
  justify-content: center;
}

.customLanguageSelectorDropdown .p-dropdown-label {
  text-overflow: unset;
}

.customLanguageSelectorDropdown span img {
  border-radius: .125rem;
}