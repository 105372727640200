.col-width-3 {
  width: 2.81%;
}

.col-width-5 {
  width: 5%;
}

.col-width-7 {
  width: 7%;
}

.col-width-10 {
  width: 10%;
}

.col-width-12 {
  width: 12%;
}

.col-width-15 {
  width: 15%;
}

.col-width-20 {
  width: 20%;
}

.col-width-25 {
  width: 25%;
}

.createdDateCol {
  width: 135px;
}

.custom-header-min-width-allowed {
  width: 10px;
}

.updatedDateCol {
  width: 115px;
}

.activationDateCol {
  width: 145px;
}

.deactivationDateCol {
  width: 163px;
}

.col-width-45 {
  width: 45px;
}

.col-width-350 {
  width: 350px;
}

.col-width-300 {
  width: 300px;
}

.col-width-250 {
  width: 250px;
}

.userAuthenticationLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F3F3F3;
  z-index: 11;
}

.removeImageBtn {
  display: inline;
  position: absolute;
  border-radius: 10em;
  padding: 3px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(53, 49, 49, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.removeImageBtn:hover {
  background: #e54e4e;
  color: white;
  border: 3px solid #fff;
  text-decoration: none;
}

.removeImageBtn:active {
  background: #e54e4e;
  color: white;
  border: 3px solid #fff;
  text-decoration: none;
}

.p-datatable .p-paginator {
  background-color: #fff;
}

.responsiveBaseDataTable .p-datatable .p-datatable-header,
.p-dialog .p-dialog-header,
.p-fieldset .p-fieldset-legend {
  background-color: #303030 !important;
  border: none;
  border-radius: var(--primary-border-top-radius);
  padding: 15px !important;
}

/* Apply padding to the selectors components */
.p-multiselect-panel .p-multiselect-items,
.p-dropdown-items-wrapper,
.p-autocomplete-items-wrapper {
  padding: 0.75rem 0;
}

/** Start to overwrite p-dialog component */
.p-dialog {
  border-radius: var(--primary-border-radius);
}

.p-dialog-content,
.p-panel-content {
  border-radius: var(--primary-border-bottom-radius) !important;
}

.p-dialog .p-dialog-header {
  background: var(--secondary-color);
  border-bottom: 2px solid var(--accent-color);
}

.p-dialog .p-dialog-footer {
  border-radius: var(--primary-border-bottom-radius);
}
/** End of overwrite p-dialog component */

td, th {
  word-break: break-word;
}

.text-overflow-ellipsis {
    position: relative;
  }

.text-overflow-ellipsis-expanded {
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 475px) {
  .text-overflow-ellipsis-expanded {
    position: relative;
  }
}

@media screen and (min-width: 475px) and (max-width: 1300px) {
  .text-overflow-ellipsis-expanded {
    position: relative;
  }
}

.text-overflow-ellipsis span,
.text-overflow-ellipsis-expanded span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

.text-overflow-ellipsis:before,
.text-overflow-ellipsis-expanded::before {
  content: '&nbsp;';
  visibility: hidden;
}

.responsiveBaseDataTable .p-paginator .p-paginator-current {
  margin-left: auto;
}

.responsiveBaseDataTable .p-paginator .p-dropdown {
  width:  4.2rem;
}

.responsiveBaseDataTable .p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 6px;
  border: none;
}

.responsiveBaseDataTable .p-datepicker {
  min-width: 25rem;
}

.responsiveBaseDataTable .p-datepicker td {
  font-weight: 400;
}

.responsiveBaseDataTable .p-datatable .p-paginator {
  padding: 1rem;
  border-radius: var(--primary-border-bottom-radius);
}

.responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td > .datatablecolumn-screen-s {
  display: none;
}

.img-fixed-height {
  max-height: 38px;
}

.nestedDatatable-withoutPagination .p-datatable table,
.datatable-withoutPagination .p-datatable table {
  border-collapse: separate;
  border-spacing: 0;
}

/* start of style for 575px breakpoint */
@media screen and (min-width: 575px) {
  .fixed-header-width {
    max-width: 40px;
  }

  .align-center-button-on-row-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 575px) {
  .p-datatable table {
    overflow: hidden;
  }
  
  .p-datatable-tbody > tr {
    position: relative;
  }

  .custom-action-column-action-position {
    position: absolute;
    top: 0;
    right: 0;
  }

  .absolute-position-responsive-screen {
    position: absolute;
  }

  .position-absolute-mr-30 {
    margin-right: 30px;
  }

  .position-absolute-mr-60 {
    margin-right: 60px;
  }

  .position-absolute-mr-90 {
    margin-right: 90px;
  }

  .position-absolute-mr-120 {
    margin-right: 120px;
  }
}

@media (min-width: 575px) and (max-width: 1200px) {
  .table-col-5 {
    width: 50px;
  }
  .table-col-10 {
    width: 100px;
  }
  .table-col-12 {
    width: 120px;
  }
  .table-col-15 {
    width: 150px;
  }
}

@media (max-width: 575px) {
  .table-col-xs-10 {
    width: 100px;
  }
}

@media screen and (max-width: 575px) {
  /* Adjust chevron icon on mobile screen. Each project has your own CSS for that */
  .responsiveBaseDataTable .p-datatable-tbody > tr > td > button > i, .responsiveBaseDataTable .p-datatable-tbody > tr > td > button > span {
    margin-left: -10px;
  }

  .custom-action-column-margin-mobile-view {
    margin-top: -3px !important;
  }

  .responsiveBaseDataTable .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
    padding: 2px 5px;
  }
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td .titleCol {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--surface-d);
  }
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td .datatablecolumn-screen-l {
    display: none;
  }
  .responsiveBaseDataTable .p-datatable .p-datatable-tbody > tr > td .datatablecolumn-screen-s {
    display: table;
    width: 100%;
  }
  .nestedDatatable-withoutPagination .p-datatable table,
  .datatable-withoutPagination .p-datatable table {
    border-collapse: collapse;
  }
}
/* end of stylef for 575px breakpoint */

/* Move this to common css by creating a new file under AbstractWebCommon/lib/common.css */
.bg-checkerboard {
  background-image: /* tint image */ linear-gradient(
      to right,
      rgba(225, 225, 225, 0.75),
      rgba(225, 225, 225, 0.75)
    ),
    /* checkered effect */ linear-gradient(to right, black 50%, white 50%),
    linear-gradient(to bottom, black 50%, white 50%);
  background-blend-mode: normal, difference, normal;
  background-size: 2em 2em;
}

.p-component,
.p-link,
.p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input,
.p-chips .p-chips-multiple-container .p-chips-input-token input,
.p-inputtext,
.p-terminal .p-terminal-input {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-size: 16px;
}
button:not(.p-button-icon-only):not(.btn-outline) {
  border-radius: 25px;
}

.btn-icon {
  font-size: 20px !important;
  margin-right: 0.5rem !important;
}

.editIcon {
  color: #007bff;
  cursor: pointer;
}

/* Adding asterisk to required fields. */
.required:after {
  content:" *";
  color: red;
}

.p-datatable .p-datatable-tbody>tr.p-highlight a, 
.p-datatable .p-datatable-tbody>tr.p-highlight>td .editIcon, 
.p-datatable .p-datatable-tbody>tr.p-highlight>td .p-row-toggler, 
.p-datatable .p-datatable-tbody>tr.p-highlight>td .p-row-toggler:enabled:hover, 
.p-datatable .p-datatable-tbody>tr.p-highlight>td>span>i {
  color: #fff !important;
}

.report-photo-comment {
  margin-left: -30px;
}
.manage-users-button {
  z-index: 1;
}
.report-display p {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.report-display h6, .report-section h6 {
  font-weight: 400;
  color: #333333;
  font-size: 16px;
}
.report-display .add-button {
  width: 50px !important;
  height: 50px !important;
}
.report-display .input-description:hover {
  background: #EBECF0;
}
.comment-text p {
  font-weight: 500;
}
.report-display button {
  height: 50px;
}
.comment-button button {
  height: 44px;
}
.comment-action-container {
  margin-left: 70px;
}
.comment-container img {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  padding: 0;
}
.comment-container #comment {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.comment-container .image-placeholder {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
}
.comment-container .p-button {
  background-color: #007ad9;
}

.comment-section {
  margin: 30px 0 0 0;
  border-radius: 5px;
}

.comment-internal {
  background:rgb(255,250,230);
  padding: 20px !important;
}

.comment-container .comment-section h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
}
.comment-text span {
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
}
.comment-text i {
  display: inline;
}
.comment-container .comment-section p {
  font-size: 14px;
  font-weight: 200;
  color: #000;
}
.comment-section .p-inplace {
  display: inline;
  margin-right: 10px;
}
.comment-section .p-inplace img {
  width: 300px;
  height: 300px;
}
.p-invalid, 
.p-autocomplete.p-invalid > .p-inputtext,
.p-error>.custom-file-label, .p-invalid>.custom-file-label {
  border-color: #a80000 !important;
  border-width: 3px !important;
}
.error-text {
  font-size: .875em;
  color: #a80000;
}
.image-container span {
  position: absolute;
  left: 13px;
  top: 13px;
  font-size: 18px !important;
  font-weight: 400 !important;
}
.img-thumbnail {
  padding: 0;
  border: 0;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #fff !important;
  color: #333333 !important;
}

.p-datatable .p-sortable-column:hover {
  background: #EBEBEB !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #848484 !important;
}

.main-body {
  padding: 10px 1rem !important;
}

.p-datatable .p-sortable-column .p-column-title, .p-datatable .p-sortable-column .p-sortable-column-icon, .p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: unset !important;
}

.headerTableContainer {
  min-height: 45px;
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .mobile-screen-width-search-bar {
    flex-wrap: wrap;
  }
  
  .headerTableContainer {
    margin-bottom: 2px;
  }
}

.shared-files label {
  font-size: 12px;
  font-weight: 500;
  line-height: 5px;
}
.icon-danger{
  color: red;
  cursor: pointer;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
  background: #fff !important;
  text-transform: capitalize !important;
}

.custom-header-btn-width {
  width: 100px
}

/* start of style for headers settings page */
.p-fieldset .p-fieldset-legend { 
  background-color: #303030 !important;
  border: none !important;
  width: 100%;
  color: #ffffff !important;
  font-weight: 700 !important;
}
/* end of style for headers settings page */
.reportsDataTable .link {
  color: #000;
}

.reportsDataTable .link:hover {
  color: #007bff;
}
.text-bold {
  font-weight: bold;
}
/* Style for Application description in Register page */
.registerDescription {
  font-size: 16px !important;
  text-align: left !important;
  margin-top: 15px;
}

/* Style for Drop-Down Filter */
.p-column-filter {
  min-width: 150px;
  color: var(--primary-background-color) !important;
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1.5rem;
}

.p-column-filter .p-multiselect-label-container {
  width: 10px;
}

@media ((min-width: 1040px) and (max-width: 1200px)) or ((min-width: 800px) and (max-width: 990px)) {
  .p-column-filter {
    min-width: 100px;
  }
  .search-input-container {
    min-width: 180px !important;
  }
}

@media ((min-width: 990px) and (max-width: 1040px)) or ((min-width: 670px) and (max-width: 800px)) {
  .p-column-filter {
    min-width: 80px;
  }
  .search-input-container {
    min-width: 100px !important;
  }
}

@media only screen and (max-width: 670px) {
  .p-column-filter {
    display: none !important;
  }
}

.header-search-filter {
  column-gap: 10px;
}

.license-card .p-card-title{
  text-align: left;
  font-size: 1.25rem !important;
}

.imageUrl {
  width: 100px;
  height: auto;
}

.dimensions-50x50 {
  width: 50px !important;
  height: 50px !important;
  object-fit: contain;
}

/* Style for modals within 991px */
@media (max-width: 991px) {
  .p-dialog {
    margin-top: 50px;
  }
}

.p-calendar .p-button:focus {
  box-shadow: none;
}

/* Start of style for fieldset component */
.p-fieldset {
  border: none !important;
}

.p-fieldset .p-fieldset-content {
  border: 1px solid #c8c8c8;
  padding: 16px;
}

.p-fieldset > legend {
  margin-bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
/* End of style for fieldset component */
.full-width-container {
  width: 100%;
}

/* Start of style for data table component */
@media screen and (max-width: 575px) {
  .p-datatable table {
    table-layout: fixed !important;
  }
}

@media screen and (min-width: 576px) {
  .p-datatable table {
    table-layout: auto !important;
  }

  .p-datatable .p-datatable-thead > tr > th {
    height: 43.25px;
  }
}

.p-datatable-thead tr th {
  white-space: nowrap;
}

.icon-column-style {
  width: 61px;
}
/* End of style for data table component */

#progress-bar .p-dialog-mask {
  align-items: flex-start;
}

#progress-bar .p-dialog {
  margin: 0.75em;
  transition: all 0.3s ease-out;
}

@media (min-width: 992px) {
  .table-width-992, #progress-bar .p-dialog, .p-dialog {
    margin-left: 250px;
  }
}

@media (min-width: 768px) {
  .table-width-992 {
    width: 75% !important;
  }
}

.custom-hide-element {
  display: none;
}

.custom-display-element {
  display: block;
}

.p-toast .p-toast-message .p-toast-message-content {
  align-items: center;
}

.p-fieldset-legend-text {
  justify-content: flex-start !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: var(--primary-control-color);
}

/* make sidebar border top use accent-color */
.sidebarCol .sidebarContainer {
  border-top: solid 4px var(--accent-color);
}

.custom-userName-width-column {
  width: 200px;
}

/* Removed border in dialog footer */
.p-dialog .p-dialog-footer {
  border: none !important;
}

/* Gradient border to the card component */
.p-card .p-card-title {
  border-bottom: 2px solid;
  border-image: linear-gradient( to right, var(--accent-color), #8127F3) 0 0 100% 0;
}

/* Overwrite default p-card class */
.p-card {
  border-radius: var(--primary-border-radius);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #333;
  background-color: #f3f3f3;
}

/* Overwrite default p-card class */
.p-card .p-card-body .p-card-content {
  padding-bottom: 0px;
}

.p-card .p-card-body {
  padding: 20px;
}

/* Overwrite custom p-card for elements that must keep the original card style. Examples: login pages and first shop page */
.login-pages-global-container .p-card, .keep-original-card-style {
  background: #fff;
  color: var(--primary-text-color);
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border: 4px solid #000;
}

/* Overwrite custom selection for login pages */
.login-pages-global-container ::selection {
  background-color: var(--primary-control-color);
}

/* Overwrite custom card title style. Example: first shop page */
.keep-original-card-title-style > .p-card-body > .p-card-title {
  border-image: none;
  border-bottom: none;
}

/* Border and background color for radio button elements */
.p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--primary-control-color);
  border-color: var(--primary-control-color);
  box-shadow: none;
}

/* Hover for radio button and checkbox elements */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover, 
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--primary-control-hover-color);
  border-color: var(--primary-control-hover-color);
  color: #fff;
}

/* Separator menu item color of Sidebar */
html .separator-menu-item {
  border-top: 1px solid;
  border-image: linear-gradient(to right, var(--accent-color), #8127F3) 100% 0 0 0;
}

/* Selection color */
body *:not(.w-md-editor-text-input)::selection {
  background-color: var(--primary-control-color);
  color: var(--selection-color-highlighted);
}

/* Assign accent color to the icon of the selected sortable table header */
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--accent-color) !important;
}

/* Overwrite Button CSS of some dropdrown components. e.g Settings page */
.p-autocomplete > .p-autocomplete-dropdown {
  border: transparent;
  background: transparent;
  border-left: transparent;
  color: grey;
  margin-left: -35px;
  border-radius: var(--primary-border-right-radius) !important;
  box-shadow: none;
}
.p-autocomplete > .p-autocomplete-dropdown:hover {
  border: transparent;
  background: transparent;
  color: grey;
}

/* Remove arrow icons from input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrow icons from input number */
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Added border radius bottom radius to p-fieldset component */
.p-fieldset .p-fieldset-content {
  border-radius: var(--primary-border-bottom-radius);
}

/* Added border radius to inputs and others elements */
.p-dropdown, 
.p-inputtext, .p-multiselect, 
.p-autocomplete-dd .p-autocomplete-input {
  border-radius: var(--primary-border-radius);
}

/* Overwrite react multi email input and p-fieldset */
.react-multi-email,
.p-fieldset {
  border-radius: var(--primary-border-radius) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-dropdown,
.p-dropdown-panel,
.p-dropdown-panel .p-dropdown-header,
.p-autocomplete-panel,
.p-multiselect-panel,
.p-multiselect-panel .p-multiselect-header {
  border-radius: var(--secondary-border-radius);
}

/* Added border radius to calendar button */
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-right-radius: var(--secondary-border-radius);
  border-bottom-right-radius: var(--secondary-border-radius);
}

/* Start of add border radius to dialog component */
.custom-dialog-container .p-dialog-content {
  border-radius: 0 !important;
}

.custom-dialog-container .p-dialog-footer {
  border-radius: var(--primary-border-bottom-radius);
}

.custom-dialog-container-with-out-header .p-dialog-content {
  border-radius: var(--primary-border-top-radius) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
/* End of add border radius to dialog component */

/* Used to keep the original border radius of Description field of Report page - License project. */
.keep-original-input-style {
  border-radius: 3px;
}

.dimensions-15x15 {
  width: 15px;
  height: 15px;
}

/* Custom class to apply our primary border radius value. */
.primary-border-radius {
  border-radius: var(--primary-border-radius);
}

/* Custom class to apply our secondary border radius value. */
.secondary-border-radius {
  border-radius: var(--secondary-border-radius) !important;
}

.custom-disabled-button {
  background-color: var(--disabled-color) !important;
  border-color: var(--disabled-color) !important;
}

.custom-disabled-icon {
  color: var(--disabled-color);
  cursor: auto;
}

.report-title {
  font-size: 28px;
  font-weight: bold;
  height: 60px;
}

.report-title, .report-description {
  padding: 0px;
  border-color: transparent;
}

.report-textarea:enabled:hover {
  background-color: aliceblue;
  border-color: transparent;
}

.p-datatable .p-datatable-tbody > tr:not(.p-highlight):hover,
.p-datatable-tbody > tr:not(.p-highlight):hover a {
  background: var(--table-row-hover-color);
}

.p-datatable .p-datatable-tbody > .p-datatable-row-expansion:hover {
  background: transparent !important;
}

/* Remove transition effect in selectors component. */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  transition: 0.0s;
}

/** Nested datatable border styling */
.nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td,
.datatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td {
  border-top-width: 0;
}
.nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td:not(.last-visible-child),
.nestedDatatable-withoutPagination .p-datatable .p-datatable-thead > tr > th:not(.last-visible-child),
.datatable-withoutPagination .p-datatable .p-datatable-tbody > tr > td:not(:last-child),
.datatable-withoutPagination .p-datatable .p-datatable-thead > tr > th:not(:last-child){
  border-right-width: 0;
}
.nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child td:first-child,
.datatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child td:nth-child(3) {
  border-bottom-left-radius: var(--primary-border-radius); 
}
.nestedDatatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child td.last-visible-child,
.datatable-withoutPagination .p-datatable .p-datatable-tbody > tr:last-child td:last-child {
  border-bottom-right-radius: var(--primary-border-radius); 
}
.customerDatatable .p-datatable .p-datatable-thead > tr:first-child th:nth-child(2) { 
  border-top-left-radius: var(--primary-border-radius);
}
.customerDatatable .p-datatable .p-datatable-tbody > tr:last-child > td:nth-child(2) { 
  border-bottom-left-radius: var(--primary-border-radius);
}
.customerDatatable .p-datatable .p-datatable-thead > tr:last-child th.last-visible-child { 
  border-top-right-radius: var(--primary-border-radius);
}

small[class="p-invalid"] {
  color: #a80000;
}

.p-column-header-content {
  justify-content: center;
}

.datatablecolumn-screen-s > tbody {
  width: 100%;
}

.popover, #confirmationPopup {
  z-index: 999999;
}

.disabled-edit-button {
  opacity: 0.5;
  pointer-events: none;
}

.button-without-icon {
  height: 44px;
}

@media (max-width: 575px) {
  .wrap-header {
    display: flex;
    flex-wrap: wrap;
  }

  .margin-bottom-on-wrap {
    margin-bottom: 6px;
  }
}

.custom-template-editor-preview-container {
  border-radius: var(--primary-border-radius);
  border: none;
}

.popover {
  margin-left: 7px !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:active {
  background: var(--primary-control-color) !important;
}
.p-splitter-gutter {
  background: transparent !important;
}
.p-splitter-gutter-resizing {
  background: #d8dae2 !important;
}
.btn {
  white-space: nowrap;
}
.p-datatable-emptymessage td {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}

.img-filter-grayscale-invert {
  filter: grayscale(1) invert(1);
}

.button-icon-margin {
  margin-left: -4px;
}

.p-button-icon-only {
  height: 40px;
}

/* Remove ring effect on click in chevron icon of data table and add highlight */
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 transparent;
  color: var(--primary-control-color);
  border-color: transparent;
  background: transparent;
}