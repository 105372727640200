.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #f2f2f2;
    background-color: #f2f2f2;
    color: #050505;
    outline: none;
    transition: border .24s ease-in-out;
  }
  .dropzone:hover {
    cursor: pointer;
    background: #edeaea;
  }
  .comment-file {
    max-width: 135px;
  }