%shared-styles {
  background-image: linear-gradient(to bottom, #27272a, #3f3f46);
  outline-color: #3f3f46;
}

.main-profile-card-container {
  // Remove the comments of these styles in case we want a specific style for dark mode.
  // @at-root .dark-mode & {
  //   @extend %shared-styles
  // }
  // &.from-sidebar-component {
  //    @extend %shared-styles;
  // }

  // Remove the next line in case we want a specific style for light mode.
  // We should add the .css or .scss file for the light mode style.
  @extend %shared-styles
}