.confirmationPopupContainer .popover-header {
  background-color: #1b1b1b;
  color: #fff;
  font-size: 16px;
  padding: 1rem ​.5rem;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  border: none;
}

.confirmationPopupContainer .popover-body {
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
}

.confirmationPopupContainer button {
  line-height: 1rem;
}