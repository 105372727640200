.user-list-container {
  max-height: 300px;
  margin-bottom: 10px;
  overflow:auto;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.user-list-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.user-list-panel > div > div {
  border: 0 solid transparent !important;
}

.user-list-panel .p-panel-header {
  border: 1px solid #333333;
  padding: 0.857rem 1rem;
  background: #333333;
  color: #ffffff;
  border-radius: 15px;
}

.delete-participant {
  padding-right: 3px;
  padding-bottom: 3px;
}

.clickable {
  cursor: pointer;
  align-self: baseline;
}

.p-chip .pi-times-circle {
  margin-left: 0.5rem;
  position: relative;
  top: 50%;
  margin-left: 1rem;
  transform: translate(-50%,-50%);
}

.p-chip .pi-times-circle:hover {
  background-color: transparent;
}

.p-chip .initial {
  width: 31px;
  height: 31px;
  margin-left: -6px;
  margin-right: 5px;
}

.p-chip .initial::before {
  width: 31px !important;
  height: 31px !important;
  line-height: 31px !important;
  font-size: 13px;
}

.active-participants {
  padding-left: 15px;
  padding-right: 15px;
}

.disabled-remove-participant {
  pointer-events: none;
}