.login-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.loginForm {
  width: 470px;
  margin: 0 auto;
}

.loginForm .p-card {
  border-radius: var(--primary-border-radius);
  margin-top: 1.5em;
}

.loginForm .negateMarginTop {
  margin-top: -24px;
}

.loginForm .specialInputs {
  height: 50px;
}

.loginForm .form-control {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.loginForm .form-control:focus {
  z-index: 2;
}

/* In case of any filled input background color, keep it white  */
.loginForm input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  background-color: white;
  background-clip: content-box;
}

.loginForm .checkbox-container {
  display: table-cell;
}

.loginForm .checkbox {
  display: inline-block;
  vertical-align: middle;
}

.loginForm .logoImage {
  width: 100%;
  object-fit: contain;
  max-height: 150px;
}

.loginForm a {
  padding-right: 0 !important;
}

hr {
  width: 90%;
}

.text-dark .usernameInput, .text-dark .passwordInput {
  border-radius: var(--primary-border-radius) !important;
}

.loginForm .p-card .p-card-content,
.registerForm .p-card .p-card-content {
  padding: 0;
}

.custom-margin {
  margin-bottom: 15px;
}

.login-form-href {
  color: #007bff;
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  padding-left: 0;
}

.login-back-button-container {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-primary:hover:not([disabled]).login-back-button-container {
  background-color: #7c8088 !important;
  border: 1px solid #7c8088 !important;
}