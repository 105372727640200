.tooltip {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif !important;
}

.tooltip, .tooltip-inner {
  border-radius: var(--secondary-border-radius);
  background: var(--primary-control-color) !important;
  z-index: 999999;
  text-align: left;
}

.tooltip .arrow {
  display: none;
}