.container-main-skeleton {
  margin-bottom: 1px;
}

.container-main-skeleton, .container-individual-skeleton span {
  height: 43.25px;
}

.container-individual-skeleton {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-individual-skeleton-margin-right {
  margin-right: 1.2px;
}

.container-individual-skeleton span {
  border-radius: 0;
}