.custom-status-column-container {
  width: 130px;
}

.custom-comment-box-field-container {
  max-width: 750px;
}

.report-form-email {
  margin-top: -15px;
}