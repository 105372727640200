.not-found-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b1b1b;
  color: white;
}


.not-found-404 {
  font-size: 128px;
  letter-spacing: 10px;
  color: #ff007d;
}