.expansion-row-container tr th {
  font-weight: 700;
}

/* logs & users & roles table : Small */
.tableExpansionSmall { display: block; white-space: normal; }
.tableExpansionSmall tr { display: inline-block; width: 500px; text-decoration: muted; }
.tableExpansionSmall th { width: 150px; height: 40px ; font-weight: normal; }

@media screen and (max-width: 605px) {
  .tableExpansionSmall tr {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .expansionContainer tr {
    display: flex;
    align-items: center;
  }

  .expansionContainer td {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .expansionContainer tr th {
    min-width: 160px;
    width: 160px;
  }
}