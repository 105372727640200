.permissions-container {
  margin-top: 48px;
  width: 474px;
}

.card-body-container {
  border-radius: 10px;
}

.custom-title-weight {
  font-weight: 700;
}

#isEulaAccepted > p {
  margin-bottom: 0;
}