.edit-license-button-container {
  width: 200px;
}

.expansion-row-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 575px) {
  .expansion-row-header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .edit-license-button-container {
    margin: 15px auto;
  }
}