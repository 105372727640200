.align-items-searchbar-input, span i {
  display: flex;
  align-items: center;
}

.p-input-icon-right, .p-input-icon-left {
  cursor: pointer;
}

.custom-p-invalid {
  color: #fff;
}

.custom-style-search-bar {
  height: 44px;
}

.align-items-searchbar-input .p-input-icon-left > .p-inputtext {
  padding-right: 25px;
}

.hide-search-icon {
  padding-left: 4px !important;
  transition: .4s;
}

.clean-icon-hover:hover {
  background: #EBEBEB;
  border-radius: 5px;
}

@media only screen and (min-width: 655px) {
  .search-input-container {
    min-width: 237px;
  }
}