.user-form .email-autocomplete {
  padding-left: 15px;
  width: 85%;
}

.email-autocomplete .p-autocomplete {
  height: 38px;
}

.email-autocomplete .p-autocomplete-multiple-container {
  flex-wrap: wrap;
}

.email-autocomplete .p-autocomplete-token {
  background-color: #f3f3f3 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 600;
  border-radius: var(--secondary-border-radius) !important;
}

.email-autocomplete .p-autocomplete-token-icon {
  padding-top: 4px;
}

.email-autocomplete .p-autocomplete-token, .p-autocomplete-token-icon, .p-autocomplete-input-token input {
  font-size: 0.8rem !important;
}

.email-autocomplete .p-autocomplete-input-token input {
  border: none;
}