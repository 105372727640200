.search-input {
  width: 200px;
}

.files-table {
  text-align: left;
  padding: 1px;
}

.files-table .table-bordered th,
.table-bordered td {
  border: 0px solid !important;
}
.files-table .table-bordered {
  border: 0px solid !important;
}

/* TODO: remove after images fix */
.small-images {
  width: 105px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
}

.expand-as-needed {
  display: block;
  overflow: auto;
}

.extension-box p {
  border-radius: 5px;
  background-color: #da735d;
  text-align: center;
}

.file-description p {
  margin-bottom: 5px;
}

.read-more {
  color: #ff007c;
  display: inline-block;
}

.read-more:hover {
  cursor: pointer;
}

.card-center {
  display: grid;
  place-items: center;
  max-width: 500px;
}

.fixed-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.download-info .header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.download-info .header > div {
  flex: 1;
}

.download-info .headerContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.download-info .left-margin {
  margin-left: 3rem;
}

.download-info .p-card-footer {
  padding-top: 0;
}

@media (max-width: 576px) {
  .download-info .headerContainer {
    flex-direction: column;    
  }

  .download-info .header {
    margin-left: 0;
    margin-top: 2rem;
  }
}

.my-licenses-sections-grid-container {
  display: grid !important;
  grid-template-columns: repeat(4, minmax(300px, 100%));
  row-gap: 15px;
}

.my-licenses-sections-grid-container-item {
  min-width: 300px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1669px) {
  .my-licenses-sections-grid-container {
    grid-template-columns: repeat(3, minmax(300px, 100%));
  }
}

@media (max-width: 1400px) {
  .my-licenses-sections-grid-container {
    grid-template-columns: repeat(2, minmax(300px, 100%));
  }
}

@media (max-width: 850px) {
  .my-licenses-sections-grid-container {
    display: flex !important;
  }

  .my-licenses-sections-flex-container-low-breakpoints {
    width: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-licenses-sections-grid-container-item {
    width: 390px;
  }
}

@media (min-width: 576px) {
  .my-products-and-unlicensed-sections-container {
    max-width: 203px;
    flex: 0 0 203px;
  }
}

@media (max-width: 575px) {
  .my-products-and-unlicensed-sections-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .my-products-and-unlicensed-sections-container-low-breakpoints {
    width: 173px;
  }
}

@media (max-width: 560px) {
  .my-products-and-unlicensed-sections-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-card-height {
  min-height: 210px !important;
  height: 100%;
}

#dashboardProduct .card:hover {
  transform: scale(1.00) translateY(-5px);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
}

.selectedProduct {
  outline: 2px solid var(--accent-color);
}

.card-project-name-label {
  color: #777;
}

.card-entitlement-margin {
  margin-bottom: 2px !important;
}

.card-status-container {
  width: max-content;
}

#productStatusTooltipID > .tooltip-inner {
  max-width: 100%;
}

.card-filesCount-container {
  background-color: #5bc0de;
  width: max-content;
  border-color: #5bc0de;
  color: white;
  border-radius: var(--secondary-border-radius);
  padding: 0.2em 0.6em 0.3em;
}

.customNameColumnWidth {
  width: 400px;
}

.customNameColumnVersion {
  width: 250px;
}

.custom-my-license-section-license-name {
  font-size: 20px !important;
}