.top-bar {
    right: 0;
    z-index: 999;
    margin-bottom: -15px;
}
.p-toolbar {
    background: transparent;
    border: none;
    padding: 0 1rem;
    border-radius: 3px;
}

hr {
    width: 95%;
}

.p-button.p-button-info:enabled:focus, 
.p-buttonset.p-button-info > .p-button:enabled:focus, 
.p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: none;
}

@media only screen and (max-width: 700px) {
    .top-bar .p-button-label {
        display: none;
    }
}

.theme-mode-button.custom-theme-mode-className .p-button-icon-left {
    color: #333333;
}

.theme-mode-button.custom-theme-mode-className:hover button span  {
    color: #212121 !important;
}

.theme-mode-button-from-login-page .p-button.p-button-text:enabled:hover {
  border-color: #212121 !important;
}