.galleria-container {
  position: relative;
  margin-left: 24px;
}

.galleria-image-container {
  width: 140px;
  height: 140px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
  opacity: 1;
}

.custom-download-action-on-image-container {
  position: absolute;
  top: -8%;
  right: 1%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #007bff;
}

.download-icon-position {
  right: -17%;
}

.custom-download-action-on-image-container:hover, .custom-delete-action-on-image-container:hover {
  cursor: pointer;
}

.request-gif-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

.custom-delete-action-on-image-container {
  position: absolute;
  top: -8%;
  right: -17%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #dc3545;
}

@media only screen and (max-width: 991px) {
  .p-galleria .p-galleria-close {
    margin-top: 70px !important;
  }
}

.p-galleria .p-galleria-close {
  z-index: 1;
}

.p-galleria .p-galleria-close,
.p-galleria .p-galleria-item-nav {
  width: 32px;
  height: 32px;
}

.p-galleria .p-galleria-item-nav {
  background: none;
}

.p-galleria .p-galleria-close .p-galleria-close-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 1rem;
}

.p-galleria .p-galleria-close .p-galleria-close-icon:hover,
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon:hover,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon:hover {
  color: #5ab7ff;
}

.p-galleria .p-galleria-close:hover,
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: none;
}

.placeholder-image { 
  filter: opacity(0.5) drop-shadow(0 0 white); 
  width: 300px;
  height: 300px;
}
.p-galleria-mask.p-component-overlay {
  background-color: black;
}

.file-attachment {
  border: 1px solid #a6a6a6;
  border-radius: var(--primary-border-radius);
  width: 190px;
  height: 205px;
}
.file-name {
  font-size: small;
  visibility: hidden;
  position: relative;
  bottom: 0;
  left: 0;
  max-width: 190px;
  text-align: center;
}
.galleria-wrapper {
  position:relative;
}
.galleria-wrapper:hover .file-name {
  visibility: visible;
  display: block;
}
.hide-scroll {
  overflow: hidden !important;
}
.p-galleria-item {
  max-width: 1000px;
  max-height: 1000px;
}