.image-container {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }
  .image-container-color {
    background: #007bff;
  }

  .image-container-border-color {
    border: 1px solid #007bff;
  }
  .image-placeholder {
    color: #ffff;
  }

  .count-placeholder{
    color: #000;
  }
  .pop-up-text {
    color: transparent;
    position: absolute;
    top: 15px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    margin-top: -15px;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 50%;
  }
  .pop-up-text ::selection {
    background: transparent !important;
    color: transparent;
  }
  .pop-up-text .text-warning {
    margin-top: 30px;
    display: flex;
  }
  .avatar-content {
    margin-top: 10px;
  }
  .pop-up-text:hover {
    cursor: pointer;
  }
  .disabled-div {
    pointer-events:none;
    opacity: 0.5;
  }