.fileRecordTableIcon {
  width: 50px;
  height: 50px;
}


.editableDescription:hover {
  cursor: pointer;
}

.negate-grid-margins {
  margin-top: -40px;
}

.responsive-custom-margin-checkbox-field {
  margin-left: 12px;
}

.version-button-container {
  min-width: 100px;
}

.activatedDateColumn {
  width: 100px;
}

@media screen and (max-width: 575px) {
  .active-column-height-mobile-screen {
    height: 30px;
  }
}

.version-button-header-container {
  width: 128.5px;
}
.editableDescription-placeholder {
  color: #555; 
}